<header>
  <div class="main">
    <div class="container inner">
      <img width="145" height="50" src="assets/logos/logo.svg" alt="Logo" />
      {{title}}
    </div>
  </div>
  <div class="container">
    <div class="header-secondary">
      <span *ngIf="viewpoint && !viewpoint.main" class="item numberplate">
        <span [innerHTML]="viewpoint.roadsign.title"></span>
        <span class="position" [innerHTML]="viewpoint.roadsign.position"></span>
      </span>
    </div>
  </div>
</header>
