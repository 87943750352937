import { Component, Input, NgZone, OnInit, Output } from '@angular/core';
import { ModalDialogComponent } from '@shared/components/modal-dialog/modal-dialog.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Scene } from 'marzipano';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-hotspot',
  templateUrl: './hotspot.component.html',
  styleUrls: ['./hotspot.component.scss'],
})
export class HotspotComponent implements OnInit {
  @Input('data') hotspot: any;
  @Input('scene') scene: Scene;
  @Input('sceneId') sceneId: string;
  @Output() modalRef: BsModalRef;
  isShowInfo: boolean = false;
  isShowInfoContent: boolean = false;
  usesPopups: boolean = false;

  constructor(
    private modalService: BsModalService,
    private ngZone: NgZone,
    private appService: AppService,
  ) {}

  ngOnInit(): void {
    this.usesPopups = this.appService.usesPopups;
  }

  ngAfterViewInit(): void {
    this.add();
  }

  add() {
    let that = this;
    // WORKAROUND: Need to wait until hotspots are in the DOM
    this.ngZone.runOutsideAngular(() => {
      setTimeout(function () {
        var element: HTMLElement = document.getElementById(
          that.sceneId + '_' + that.hotspot.slug
        );
        var position = { yaw: that.hotspot.yaw, pitch: that.hotspot.pitch };
        var position = { yaw: that.hotspot.yaw, pitch: that.hotspot.pitch };
        element.style.opacity = '1';
        that.scene.hotspotContainer().createHotspot(element, position);
      }, 0);
    });
  }

  openDialog() {
    const initialState = {
      data: this.hotspot,
    };
    this.modalRef = this.modalService.show(ModalDialogComponent, {
      initialState,
      class: 'modal-xl',
      keyboard: true,
      ignoreBackdropClick: false,
    });
  }

  toggleInfo() {
    if (this.isShowInfo) {
      this.isShowInfoContent = false;
      this.ngZone.runOutsideAngular(() => {
        setTimeout(() => {
          this.isShowInfo = false;
        }, 500);
      });
    } else {
      this.isShowInfo = true;
      this.ngZone.runOutsideAngular(() => {
        setTimeout(() => {
          this.isShowInfoContent = true;
        }, 500);
      });
    }
  }
}
