import { Injectable } from '@angular/core';
import * as COMPANY_DATA from '@data/company.data.json';
import { app as appConfig } from '@data/project.data.json';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  app = appConfig;
  home = appConfig.homeLayout;
  title = appConfig.title;
  appType = appConfig.type;
  heading = COMPANY_DATA.heading;
  usesPopups = appConfig.popups;
  isStandalone = this.appType === 'standalone' ? true : false;

  constructor() { }
}